<template>
	<div ref="target" class="height-100 p-200-md module-md account-value-chart-module">
		<div
			v-if="isLoading || isError"
			class="display-flex align-items-center-lg flex-direction-column-mdo flex-direction-column-smo gap-100"
		>
			<ModularDashboardPlaceholderLines
				:line-count="1"
				:show-header="true"
				class="flex-1"
				:use-animation="isLoading"
			/>
			<ModularDashboardPlaceholderLines
				:line-count="isDesktop ? 1 : 2"
				:show-header="isDesktop"
				class="flex-1"
				:use-animation="isLoading"
			/>
			<ModularDashboardPlaceholderLines
				v-if="isDesktop"
				:line-count="1"
				:show-header="true"
				class="flex-1"
				:use-animation="isLoading"
			/>
		</div>
		<ModularDashboardPlaceholderBlock
			:placehold="isLoading || isError"
			:show-error-icon="isError"
			:error-message="moduleLoadingErrorMessage"
			:class="{ 'py-100': isLoading || isError }"
			classes="value-chart-module-placeholder-block"
			:use-animation="isLoading"
			@retry-clicked="loadModule()"
		>
			<div
				v-if="isInPreSettlementState"
				class="display-flex flex-direction-column align-items-center p-100 px-400-md py-600-md mb-500-lg mt-100-lg"
			>
				<BaseSvgIcon height="140" width="140" name="hd-chart-placeholder-pre-settlement" class="icon" />
				<p class="text-align-center mt-0">{{ accountStatusDescription }}</p>
			</div>
			<div v-else>
				<NetReturnsOverTimeByTimeHorizonContainer
					v-if="isPerformanceContextEnabled"
					:chart-data="chartDataByTimeHorizon"
					:chart-annotations="annotations"
					:active-annotations="activeAnnotations"
					@active-annotations="onActiveAnnotationUpdate"
					@mouseleave="onChartBlurred"
				/>

				<NetReturnsOverTimeChartContainer
					v-else
					:chart-data="chartDataByInterval"
					:chart-interval="chartInterval"
					:chips="chips"
					:use-large-screen-styles="!isMobile"
					:value-item-labels="valueItemLabels"
					@chart-interval="onChartIntervalUpdate"
				>
					<template #change-since-last-login>
						<div
							v-if="displaySinceLastLogin"
							class="ml-50 display-flex flex-direction-column text-align-right"
							data-test="change-since-last-login"
						>
							<div class="display-flex align-items-center gap-50 ml-auto">
								<span data-test="return-indicator" :class="returnIndicator"></span>
								<span class="paragraph-larger font-weight-bold" :class="returnsColor">{{
									changeSinceLastLoginDisplay
								}}</span>
							</div>
							<span class="disclaimer">Since {{ lastLoginDate }}</span>
						</div>
					</template>
					<template #cta>
						<BaseCtaLink
							v-if="!isMobile"
							:link="portfolioLink"
							data-test="view-more-detail-cta"
							icon="arrow"
						/>
					</template>
				</NetReturnsOverTimeChartContainer>

				<TransitionCollapse>
					<div v-if="performanceContextCards.length" class="mt-50">
						<PerformanceContextCarousel
							:cards="performanceContextCards"
							@carousel-scrolled="handleCarouselScrolled"
						/>
					</div>
				</TransitionCollapse>

				<MicroFeedbackPerformanceContextModule
					v-if="isPerformanceContextMicroFeedbackEnabled"
					:classes="performanceContextCards.length ? 'mt-100' : 'mt-50'"
				/>
			</div>
		</ModularDashboardPlaceholderBlock>
		<ModularDashboardPlaceholderLines v-if="isLoading || isError" :line-count="0" :show-footer="true" />
	</div>
</template>

<script lang="ts">
export default {
	name: 'AccountValueChartModule'
};
</script>

<script setup lang="ts">
import { AccountValueChartModuleMetadata, ModularDashboardModule } from 'types/modular-dashboard';
import { Chip, Link } from 'types/layout';
import { computed, onMounted, ref, watch } from 'vue';
import {
	formattedPerformanceValue,
	getPerformanceReturnIndicator,
	getReturnsColor
} from '@utils/performance-portfolio';
import { isDesktop, isMobile } from '@utils/composables';
import {
	NetReturnsOverTimeByTimeHorizon,
	NetReturnsOverTimeChartDataPoint,
	NetReturnsOverTimeChartIntervalOption,
	NetReturnsOverTimeChartValueItemLabels,
	NetReturnsOverTimeData
} from 'types/account';
import { trackMixpanelEvent, trackMixpanelScrollIntoView } from '@utils/analytics';
import { ValueOverTimeAnnotation, ValueOverTimeAnnotationIconType } from 'types/charts/value-over-time-chart';
import { app } from '@store/modules/app';
import big from 'big.js';
import { CustomSpacetimeFormat } from 'types/spacetime-formats';
import MicroFeedbackPerformanceContextModule from '@components/micro-feedback/micro-feedback-performance-context-module.vue';
import { modularDashboard } from '@store/modules/modular-dashboard';
import ModularDashboardPlaceholderBlock from '@components/account/modular-dashboard/modular-dashboard-placeholder-block.vue';
import ModularDashboardPlaceholderLines from '@components/account/modular-dashboard/modular-dashboard-placeholder-lines.vue';
import { moduleLoadingErrorMessage } from '@constants/modular-dashboard';
import NetReturnsOverTimeByTimeHorizonContainer from '@components/account/net-returns-over-time-by-time-horizon-container.vue';
import NetReturnsOverTimeChartContainer from '@components/account/net-returns-over-time-chart-container.vue';
import { PerformanceContextCardType } from 'types/performance-context';
import PerformanceContextCarousel from '@components/account/performance-context/performance-context-carousel.vue';
import spacetime from 'spacetime';
import TransitionCollapse from '@components/layout/transition-collapse.vue';

interface Props {
	module: ModularDashboardModule<AccountValueChartModuleMetadata>;
}

const props = defineProps<Props>();

const target = ref<HTMLElement | null>(null);

const valueItemLabels: NetReturnsOverTimeChartValueItemLabels = {
	valueLabel: 'Account value',
	contributionLabel: 'Net contribution',
	returnLabel: 'Net return'
};

const portfolioLink: Link = { router: 'account-portfolio', text: 'View more detail' };

const isLoading = ref(true);
const isError = ref(false);
const hasCarouselScrolled = ref(false);
const isPerformanceContextMicroFeedbackEnabled = ref(false);

const chips = ref([] as Array<Chip>);

const chartDataByTimeHorizon = computed((): NetReturnsOverTimeByTimeHorizon | null => {
	const timeHorizon = modularDashboard.accountValueChartActiveTimeHorizon?.name;
	return modularDashboard.accountValueChartDataByTimeHorizon && timeHorizon
		? modularDashboard.accountValueChartDataByTimeHorizon[timeHorizon] ?? null
		: null;
});

const chartDataByInterval = computed(
	(): NetReturnsOverTimeData | null => modularDashboard.accountValueChartDataByInterval
);

function getAnnotationIconType(cardType: PerformanceContextCardType): ValueOverTimeAnnotationIconType {
	return cardType === 'PERFORMANCE' ? 'PERFORMANCE' : 'DEFAULT';
}

const annotations = computed((): Array<ValueOverTimeAnnotation> => {
	const timeHorizon = modularDashboard.accountValueChartActiveTimeHorizon?.name;
	if (timeHorizon) {
		const data = modularDashboard.accountValuePerformanceContext.events[timeHorizon] ?? [];

		return data.map((contextEvent) => {
			const formattedDate = spacetime(contextEvent.eventDate, 'UTC').epoch;
			const nearestChartPoint = chartDataByTimeHorizon.value?.returnsOnDateDtos.find(
				(point) => spacetime(point.date, 'UTC').epoch >= formattedDate
			);
			const datePoint = nearestChartPoint ? spacetime(nearestChartPoint.date, 'UTC').epoch : formattedDate;
			return {
				date: datePoint,
				id: contextEvent.eventName,
				icon: getAnnotationIconType(contextEvent.cardData.type)
			};
		});
	}
	return [];
});

const activeAnnotations = computed(() => modularDashboard.accountValuePerformanceContext.activeEventIds);

const performanceContextCards = computed(() => {
	const timeHorizon = modularDashboard.accountValuePerformanceContext.timeHorizon;
	if (timeHorizon) {
		return modularDashboard.accountValuePerformanceContext.events[timeHorizon];
	} else {
		return [];
	}
});

const chartInterval = computed(
	(): NetReturnsOverTimeChartIntervalOption | null => modularDashboard.accountValueChartInterval
);

const isInPreSettlementState = computed((): boolean => modularDashboard.isInPreSettlementState);

const accountStatusDescription = computed((): string => modularDashboard.accountStatusDescription);

const monthlyReturnsByDate = computed(
	(): Array<NetReturnsOverTimeChartDataPoint> => chartDataByInterval.value?.monthlyReturnsByDate ?? []
);

const weeklyReturnsByDate = computed(
	(): Array<NetReturnsOverTimeChartDataPoint> => chartDataByInterval.value?.weeklyReturnsByDate ?? []
);

const dailyReturnsByDate = computed(
	(): Array<NetReturnsOverTimeChartDataPoint> => chartDataByInterval.value?.dailyReturnsByDate ?? []
);

const valueChangeSinceLastLogin = computed(
	(): string => modularDashboard.accountValueChange?.accountValueChangeSinceLastLogin ?? '0'
);

const displaySinceLastLogin = computed(
	(): boolean => isMobile.value && big(valueChangeSinceLastLogin.value).abs().gt('0.00')
);

const changeSinceLastLoginDisplay = computed((): string =>
	formattedPerformanceValue(valueChangeSinceLastLogin.value, 'currency')
);

const returnsColor = computed((): string => getReturnsColor(valueChangeSinceLastLogin.value));

const returnIndicator = computed((): string =>
	valueChangeSinceLastLogin.value ? getPerformanceReturnIndicator(valueChangeSinceLastLogin.value) : ''
);

const lastLoginDate = computed((): string =>
	spacetime(modularDashboard.accountValueChange?.lastLoginDate).format(CustomSpacetimeFormat.MONTH_DATE)
);

const isPerformanceContextEnabled = computed((): boolean => modularDashboard.performanceContextEnabled);

watch(
	() => chartDataByInterval.value,
	(newData) => {
		chips.value = Object.entries(newData ?? {}).reduce((chipsToReturn, [key, value]) => {
			if (value.length) {
				chipsToReturn.push({ value: key, label: chipLabel(key) });
			}

			return chipsToReturn;
		}, [] as Array<Chip>);

		if (chips.value.length) {
			selectStartingInterval();
		}
	},
	{ immediate: true }
);

onMounted(() => {
	if (target.value) {
		trackMixpanelScrollIntoView(target.value, {
			'View Title': 'Account Value Chart',
			'View Content': props.module.name,
			'View ID': props.module.type
		});
	}
});

function chipLabel(interval: string): string {
	if (interval === 'dailyReturnsByDate') return 'Daily';
	if (interval === 'weeklyReturnsByDate') return 'Weekly';
	else return 'Monthly';
}

function onChartIntervalUpdate(interval: NetReturnsOverTimeChartIntervalOption | null): void {
	modularDashboard.updateAccountValueChartInterval(interval);
}

function onActiveAnnotationUpdate(annotations: Array<ValueOverTimeAnnotation>): void {
	const ids = annotations.map((annotation) => annotation.id);
	modularDashboard.setAccountValueChartActivePerformanceContextEvents(ids);
}

function onChartBlurred(): void {
	modularDashboard.setAccountValueChartActivePerformanceContextEvents([]);
}

function selectStartingInterval(): void {
	if (monthlyReturnsByDate.value.length >= 36) {
		modularDashboard.updateAccountValueChartInterval('monthlyReturnsByDate');
	} else if (weeklyReturnsByDate.value.length >= 12) {
		modularDashboard.updateAccountValueChartInterval('weeklyReturnsByDate');
	} else if (dailyReturnsByDate.value.length) {
		modularDashboard.updateAccountValueChartInterval('dailyReturnsByDate');
	} else {
		modularDashboard.updateAccountValueChartInterval(
			(chips.value[0]?.value as NetReturnsOverTimeChartIntervalOption) ?? null
		);
	}
}

function handleCarouselScrolled(): void {
	if (!hasCarouselScrolled.value) {
		hasCarouselScrolled.value = true;
		trackMixpanelEvent('Action', {
			'Action Target': 'View scrolled',
			'Action Context': props.module.title ?? props.module.name
		});
	}
}

async function loadModule(): Promise<void> {
	isLoading.value = true;
	isError.value = false;

	try {
		if (!isInPreSettlementState.value) {
			if (isPerformanceContextEnabled.value) {
				await modularDashboard.storeAccountValueAndPerformanceContextData();
				isPerformanceContextMicroFeedbackEnabled.value = await app.isFeatureEnabled(
					'prod-1893-performance-context-microfeedback-module'
				);
			} else {
				await Promise.all([
					modularDashboard.updateAccountValueChart(),
					modularDashboard.storeAccountValueChange()
				]);
			}
		}
	} catch {
		isError.value = true;
	} finally {
		isLoading.value = false;
	}
}

async function setup(): Promise<void> {
	await loadModule();
}

setup();
</script>

<style lang="scss">
@use '../../../../../styles/components/performance-portfolio';
@use '../../../../../styles/constants/_colors.scss' as *;
@use '../../../../../styles/utilities/respond-to.scss' as *;

.account-value-chart-module {
	@include respond-to(smo) {
		background: #fff;
		border-bottom: 1px solid gray(20);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
		padding: 1rem 1rem 1.5rem;
	}
}

:deep(.value-chart-module-placeholder-block) {
	width: 100%;
	height: 350px;
}

.value-chart-module-account-value {
	.svg-icon {
		display: none;
	}

	&:hover {
		color: currentColor;
		.svg-icon {
			display: block;
		}
	}
}

.value-chart-module-range-label {
	.svg-icon {
		margin-top: 2px;
		path {
			fill: currentColor;
		}
	}
}
</style>
