<template>
	<div
		:class="[
			'chips-container',
			{ 'chips-container--horizontal-scroll-smo': props.scrollsOnMobile },
			`chips--${props.size}`
		]"
	>
		<BaseButton
			v-for="chip in props.chips"
			:key="chip.value"
			class="chip"
			:class="{ 'chip-pro': props.isPro, selected: isSelected(chip), disabled: chip.disabled }"
			:disabled="chip.disabled"
			:is-active="isSelected(chip)"
			:controls="props.controls"
			:click-text="`chip-${chip.value}`"
			:data-test="`chip-${chip.value}`"
			mixpanel-element="Chip"
			:mixpanel-context="mixpanelContext"
			:mixpanel-target="chip.label"
			:no-style="true"
			@click="handleClick(chip)"
		>
			<span class="chip-content">
				<BaseSvgIcon v-if="chip.icon" :name="chip.icon" :class="{ 'chip-icon': chip.icon }" />
				{{ chip.label }}
			</span>
		</BaseButton>
	</div>
</template>

<script setup lang="ts">
import { Chip, ChipsSize } from 'types/layout';

interface Props {
	controls?: string;
	chips: Array<Chip>;
	mixpanelContext?: string;
	selected?: Chip | null;
	scrollsOnMobile?: boolean;
	isPro?: boolean;
	size?: ChipsSize;
	modelValue?: Chip | null;
}

const props = withDefaults(defineProps<Props>(), {
	controls: undefined,
	mixpanelContext: undefined,
	selected: undefined,
	scrollsOnMobile: false,
	isPro: false,
	size: 'sm',
	modelValue: undefined
});

interface Emits {
	(e: 'update:modelValue', value: Chip | undefined): void;
}
const emit = defineEmits<Emits>();

function isSelected(chip: Chip): boolean {
	return chip.value === props.selected?.value;
}

function handleClick(selection?: Chip) {
	emit('update:modelValue', selection);
}
</script>
