<template>
	<component
		:is="props.component"
		:id="props.buttonId"
		ref="bButton"
		:aria-controls="props.controls"
		:aria-disabled="props.disabled"
		:aria-describedby="props.describedBy"
		:aria-label="props.ariaLabel"
		:aria-labelledby="props.labelledBy"
		:aria-selected="props.isActive"
		:class="[
			buttonClasses,
			isWorkingClasses,
			{ 'base-button-wrapper': props.component !== 'button' },
			{ 'button-mini': props.size === 'mini' }
		]"
		:disabled="props.disabled"
		:role="roleAttribute"
		:tabindex="props.isActive || props.disabled ? -1 : 0"
		:value="props.clickText"
		:data-test="dataAttribute"
		:type="buttonType"
		@keyup.enter="handleTracking($event)"
		@click="handleTracking($event)"
		><slot
	/></component>
</template>

<script setup lang="ts">
import { computed, onMounted, onUpdated, ref } from 'vue';
import { MixpanelContext } from 'types/analytics';
import { trackMixpanelClick } from '@utils/analytics';
import { useAnalyticsProperties } from '@utils/composables/use-analytics-properties';
import { useCurrentElement } from '@vueuse/core';

interface Props {
	ariaLabel?: string;
	buttonId?: string;
	classes?: string;
	clickText?: string;
	// Pass a component or a string of an HTML element ('div', 'span', etc.)
	component?: string;
	// Value for aria-controls. Corresponds to the id of the element that the button controls.
	controls?: string;
	describedBy?: string;
	// Toggles the disabled or aria-disabled attribute
	disabled?: boolean;
	// If button requires an active/selected state, this will disable focus.
	isActive?: boolean;
	// Loading for async actions
	isWorking?: boolean;
	labelledBy?: string;
	noStyle?: boolean;
	size?: 'medium' | 'mini';
	role?: string;
	type?: 'submit' | 'reset' | 'button';
	mixpanelElement?: string;
	mixpanelTarget?: string;
	mixpanelContext?: string;
	mixpanelCustomProperties?: MixpanelContext;
}

interface Emits {
	(e: 'click', value: Event): void;
}

const props = withDefaults(defineProps<Props>(), {
	ariaLabel: undefined,
	buttonId: undefined,
	classes: 'button button-primary',
	clickText: undefined,
	component: 'button',
	controls: undefined,
	describedBy: undefined,
	disabled: false,
	isActive: false,
	isWorking: false,
	labelledBy: undefined,
	noStyle: false,
	role: undefined,
	type: 'button',
	size: 'medium',
	mixpanelElement: 'Button',
	mixpanelTarget: undefined,
	mixpanelContext: undefined,
	mixpanelCustomProperties: undefined
});

const emits = defineEmits<Emits>();
const { getActionProperties } = useAnalyticsProperties();

const bButton = ref<HTMLElement | null>(null);

const buttonClasses = computed((): string => {
	if (props.noStyle) {
		return '';
	}
	return `${props.classes}`;
});

const buttonType = computed((): string | undefined => (isAButton.value ? props.type : undefined));

const dataAttribute = computed((): string => (isAButton.value ? 'base-button' : 'base-button-wrapper'));

const isAButton = computed((): boolean => props.component === 'button');

const isWorkingClasses = computed((): string => {
	if (props.isWorking) {
		return 'active button-loading';
	}
	return '';
});

const roleAttribute = computed((): string | undefined => (isAButton.value ? undefined : props.role || 'button'));

onMounted(() => {
	validateAriaProperties();
});

onUpdated(() => {
	validateAriaProperties();
});

function handleTracking($event: Event): void {
	if (!props.disabled && !props.isWorking && bButton.value) {
		emits('click', $event);
		if ($event.type === 'keyup' && props.component === 'button') {
			return;
		}

		trackMixpanelClick(
			getActionProperties({
				'Action Element': props.mixpanelElement ?? '',
				'Action Target': props.mixpanelTarget ?? props.clickText,
				'Action Context': props.mixpanelContext,
				element: bButton.value,
				customProperties: { ...props.mixpanelCustomProperties }
			})
		);
	}
}

function validateAriaProperties(): void {
	if (!isAButton.value && !props.ariaLabel && !props.labelledBy) {
		const el = useCurrentElement();
		(el.value as HTMLElement).classList.add('display-none');
		throw new Error(
			'BaseButton: If not using a <button> element for component, either the labelledBy or ariaLabel prop are required.'
		);
	}
}
</script>

<style lang="scss" scoped>
.base-button-wrapper {
	&:hover {
		cursor: pointer;
	}

	&[aria-selected='true'] {
		pointer-events: none;
	}
}

button[aria-selected='true'] {
	pointer-events: none;
}

[aria-disabled='true'],
[aria-disabled='true'].base-button-wrapper,
[disabled] {
	cursor: not-allowed;
}
</style>
