import { fundriseEfundReitId, intervalFundReitIds, ipoReitId, mergedEfundReitIds, ofundReitId } from '@constants/reit';
import { app } from '@store/modules/app';
import { FundriseRouteConfig } from 'vue-router';
import { getOfferingIdFromSlug } from '@api/offering-details';
import { handleUnauthenciatedState } from '@router/auth-guard-helpers';
import { investmentEntity } from '@store/modules/investment-entity';
import { ipo } from '@store/modules/ipo';
import { isANumber } from '@utils/dev';
import { isAuthenticated } from '@utils/composables';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { replaceRoute } from '@utils/client';
import { user } from '@store/modules/user';

const resolveOfferingRoute = async (offeringId: string) => {
	if (offeringId === ipoReitId) {
		return { name: 'offerings-ipo-landing', replace: true };
	}

	if (mergedEfundReitIds.includes(offeringId)) {
		return { name: 'view-offering-details', params: { offeringId: fundriseEfundReitId }, replace: true };
	}

	if (!isANumber(offeringId)) {
		try {
			const offeringData: { id: string } = await getOfferingIdFromSlug(offeringId);
			return { name: 'view-offering-details', params: { offeringId: offeringData.id }, replace: true };
		} catch (e) {
			return { name: 'offerings' };
		}
	}
	const includesIntervalFundReitIds =
		intervalFundReitIds.includes(offeringId) && !user.isExistingInvestor && !app.isAdvisor;
	if (includesIntervalFundReitIds && offeringId === '24') {
		replaceRoute('https://fundriseintervalfund.com');
	}

	if (includesIntervalFundReitIds && offeringId === '25') {
		replaceRoute('https://fundriseincomerealestatefund.com/');
	}

	if (!includesIntervalFundReitIds) {
		return true;
	}
};
const offeringsRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/offerings`,
		name: 'offerings',
		alias: ['/direct-investments', '/direct-investment'],
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			if (
				to.path.startsWith('/direct-investment') &&
				investmentEntity.featureEligibility.directInvest !== 'ELIGIBLE'
			) {
				next({ name: 'offerings', replace: true });
			} else if (to.path === '/offerings' && investmentEntity.featureEligibility.directInvest === 'ELIGIBLE') {
				next({ path: '/direct-investments', replace: true });
			} else {
				next();
			}
		},
		component: () => import('@views/offerings/offerings-view.vue')
	},
	{
		path: '/offerings/fundrise-ipo/view',
		name: 'offerings-ipo-landing',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/offerings/offerings-ipo-landing.vue'),
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.isIraEntity) {
				next({ name: 'account-overview', replace: true });
			} else if (investmentEntity.hasNeverBeenAnInvestor) {
				next({ name: 'user-overview', replace: true });
			} else {
				await ipo.fetchAndStoreMetadata();
				await ipo.fetchAndStoreCurrentPrincipal();

				next();
			}
		}
	},
	{
		path: '/offerings/fundrise-ipo/mobile-view',
		name: 'offerings-ipo-landing-mobile',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		component: () => import('@views/offerings/offerings-ipo-landing-mobile.vue')
	},
	{
		path: `/offerings/opportunity-fund/view`,
		name: 'view-opportunity-fund-details',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: {
			name: 'view-offering-details',
			params: {
				offeringId: ofundReitId
			}
		}
	},
	{
		path: `/offerings/:offeringId/view`,
		name: 'view-offering-details',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to) => {
			const resolvedRoute = await resolveOfferingRoute(to.params.offeringId as string);
			if (to.redirectedFrom?.name === 'view-offering-details-authenticated' && !isAuthenticated.value) {
				return await handleUnauthenciatedState(to, resolvedRoute);
			}

			if (isAuthenticated.value) {
				await modularDashboard.storePerformanceContextEnabled();
			}

			return resolvedRoute;
		},
		component: () => import('@views/offering-details/offering-details-wrapper.vue')
	},
	{
		path: `/offerings/auth/:offeringId/view`,
		name: 'view-offering-details-authenticated',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: (to) => {
			return {
				name: 'view-offering-details',
				params: {
					offeringId: to.params.offeringId
				}
			};
		}
	},
	{
		path: `/reit/:offeringId/view`,
		name: 'view-reit-details-portfolio',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'view-offering-details' }
	},
	{
		path: `/reits/:offeringId/view`,
		name: 'view-reit-details',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		redirect: { name: 'view-offering-details' }
	},
	{
		path: '/fundriseintervalfund-iframe-only/:offeringId',
		name: 'view-offering-details-iframe',
		meta: { minTokenScopeRequired: 'UNAUTHENTICATED' },
		beforeEnter: async (to, from, next) => {
			const offeringId = to.params.offeringId as string;
			if (!isANumber(offeringId)) {
				try {
					const offeringData: { id: string } = await getOfferingIdFromSlug(offeringId);
					next({ name: 'view-offering-details-iframe', params: { offeringId: offeringData.id } });
				} catch (e) {
					next({ name: 'offerings' });
				}
			} else {
				next();
			}
		},
		component: () => import('@views/offering-details/offering-details-wrapper.vue')
	}
];

export default offeringsRoutes;
