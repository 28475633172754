import { FundriseRouteConfig, NavigationGuard } from 'vue-router';
import { advisor } from '@store/modules/advisor';
import { app } from '@store/modules/app';
import { customPlans } from '@store/modules/custom-plans';
import { investmentEntity } from '@store/modules/investment-entity';
import { redirect } from '@utils/client';
import { usePlansStore } from '@stores/plans';
import { user } from '@store/modules/user';

const isFundriseProGuard: NavigationGuard = (to, from, next) => {
	if (user.hasActiveProStatus) {
		next();
	} else {
		next({ name: 'account-overview' });
	}
};

const ineligibleForDripNavGuard: NavigationGuard = async (to, from, next) => {
	if (investmentEntity.entityType === 'LLC') {
		await investmentEntity.storeEntityAccountDetails();
		if (investmentEntity.entityAccountDetails?.taxClassification === 'DISREGARDED_ENTITY') {
			next({
				name: 'settings-entity-account-information-edit'
			});
		} else {
			next({ name: 'ineligible-for-drip' });
		}
	} else if (investmentEntity.entityType === 'TRUST') {
		await investmentEntity.storeTrustAccountDetails();
		if (!investmentEntity.trustAccountDetails?.trustClassification) {
			next({
				name: 'settings-trust-information-edit'
			});
		} else {
			next({ name: 'ineligible-for-drip' });
		}
	} else {
		next({ name: 'ineligible-for-drip' });
	}
};

/* eslint-disable @typescript-eslint/explicit-function-return-type */
const settingsRoutes: Array<FundriseRouteConfig> = [
	{
		path: `/account/profile/investment-accounts`,
		name: 'profile-investment-accounts-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/profile/profile-investment-accounts-view.vue')
	},
	{
		path: `/account/profile/email/edit`,
		alias: `/account/email/form`,
		name: 'profile-email-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/profile/profile-email-edit.vue')
	},
	{
		path: '/account/profile/identification/edit',
		name: 'profile-identification-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: (to, from, next) => {
			if (user.ssnNeedsUpdating || !user.hasDateOfBirth) {
				next();
			} else {
				next({ name: 'profile-personal-information-view', replace: true });
			}
		},
		component: () => import('@views/settings/profile/profile-identification-edit.vue')
	},
	{
		path: `/account/profile/fundrise-pro`,
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: isFundriseProGuard,
		component: () => import('@views/settings/profile/fundrise-pro/profile-fundrise-pro.vue'),
		children: [
			{
				path: '',
				name: 'profile-fundrise-pro',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: isFundriseProGuard,
				component: () => import('@views/settings/profile/fundrise-pro/profile-fundrise-pro-overview.vue')
			},
			{
				path: `cancel`,
				name: 'profile-fundrise-pro-cancel',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: (to, from, next) => {
					if (user.canCancelFundrisePro) {
						next();
					} else {
						next({ name: 'profile-fundrise-pro', replace: true });
					}
				},
				component: () => import('@views/settings/profile/fundrise-pro/profile-fundrise-pro-cancel.vue')
			},
			{
				path: 'cancelled',
				name: 'profile-fundrise-pro-cancelled',
				meta: { minTokenScopeRequired: 'FULL_ACCESS' },
				beforeEnter: (to, from, next) => {
					if (user.hasCancelledFundrisePro) {
						next();
					} else {
						next({ name: 'profile-fundrise-pro', replace: true });
					}
				},
				component: () => import('@views/settings/profile/fundrise-pro/profile-fundrise-pro-cancelled.vue')
			}
		]
	},
	{
		path: `/account/profile/pro-features`,
		name: 'profile-pro-features',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: isFundriseProGuard,
		component: () => import('@views/settings/profile/profile-fundrise-pro-features.vue')
	},
	{
		path: `/account/profile/notifications`,
		alias: `/account/notifications/view`,
		name: 'profile-notifications-view',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		component: () => import('@views/settings/profile/profile-notifications-view.vue')
	},
	{
		path: `/account/profile/notifications/edit`,
		alias: `/account/notifications/edit`,
		name: 'profile-notifications-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/profile/profile-notifications-edit.vue')
	},
	{
		path: `/account/profile/security`,
		name: 'profile-security-view',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			await Promise.all([user.getAuthorizations(), user.storeDevices(), user.storeTwoFactorType()]);

			next();
		},
		component: () => import('@views/settings/profile/profile-security-view.vue')
	},
	{
		path: `/account/profile/investment-accounts`,
		name: 'settings-investment-accounts',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		redirect: { name: 'account-overview' }
	},
	{
		path: `/account/settings/joint-account-information`,
		alias: `/account/investment-accounts/joint-account/personal-information`,
		name: 'settings-joint-account-information-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'JOINT_ACCOUNT') {
				await investmentEntity.storeJointAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-joint-account-information-view.vue')
	},
	{
		path: `/account/settings/joint-account-information/edit`,
		alias: `/account/investment-accounts/joint-account/account-holder-information/edit`,
		name: 'settings-joint-account-information-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'JOINT_ACCOUNT') {
				await investmentEntity.storeJointAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-joint-account-information-edit.vue')
	},
	{
		path: `/account/profile/investment-accounts/joint-account/create`,
		alias: '/account/investment-accounts/new/joint-account/create',
		name: 'settings-joint-account-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.storeAddress();
			next();
		},
		component: () => import('@views/settings/settings-joint-account-information-edit.vue')
	},
	{
		path: `/account/settings/recurring-investment/edit`,
		alias: '/account/investment-accounts/recurring-investment-settings/manage',
		beforeEnter: async (to, from, next) => {
			if (!investmentEntity.hasActiveRecurringInvestmentSchedule) {
				next({ name: 'auto-invest-enable', replace: true });
			} else if (investmentEntity.eligibleForRecurringInvestment) {
				await investmentEntity.storeAutoInvestSchedule();
				await investmentEntity.storeBankAccounts();
				next();
			} else {
				redirect('/account/overview');
			}
		},
		name: 'settings-auto-invest-form',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-auto-invest-form.vue')
	},
	{
		path: `/account/settings/recurring-investment`,
		alias: '/account/investment-accounts/recurring-investment-settings/view',
		name: 'settings-auto-invest-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.eligibleForRecurringInvestment) {
				if (investmentEntity.hasActiveRecurringInvestmentSchedule) {
					await investmentEntity.storeAutoInvestSchedule(true);
					await investmentEntity.storeBankAccounts(true);
				}
				next();
			} else {
				redirect('/account/overview');
			}
		},
		component: () => import('@views/settings/settings-auto-invest-view.vue')
	},
	{
		path: `/account/settings/reinvestment`,
		alias: [
			'/account/settings/dividend-reinvestment',
			'/account/investment-accounts/dividend-reinvestment/view',
			'/account/investment-accounts/ria-dividend-reinvestment/view'
		],
		name: 'settings-reinvestment-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.isEligibleForReinvestment) {
				if (investmentEntity.eligibleForDrip) {
					await investmentEntity.storeDripPreference();
					await investmentEntity.storeBankAccounts();
				}
				if (!investmentEntity.isLegacyInvestorWithoutPlan) {
					await investmentEntity.storeNAVReinvestmentPreference();
				}
				next();
			} else {
				await ineligibleForDripNavGuard(to, from, next);
			}
		},
		component: () => import('@views/settings/settings-reinvestment-view.vue')
	},
	{
		path: `/account/settings/dividend-reinvestment/edit`,
		alias: [
			'/account/investment-accounts/dividend-reinvestment/manage',
			'/account/investment-accounts/ria-dividend-reinvestment/manage'
		],
		name: 'settings-drip-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeBankAccounts();
			const shouldRouteToViewPage =
				investmentEntity.bankAccounts.length === 0 && investmentEntity.entityType !== 'IRA';
			if (investmentEntity.isLegacyInvestorWithCashDistribution || shouldRouteToViewPage) {
				next({
					name: 'settings-reinvestment-view'
				});
			} else if (investmentEntity.eligibleForDrip) {
				await investmentEntity.storeDripPreference();
				next();
			} else {
				await ineligibleForDripNavGuard(to, from, next);
			}
		},
		component: () => import('@views/settings/settings-drip-edit.vue')
	},
	{
		path: `/account/profile/investment-accounts/entity/create`,
		alias: `/account/investment-accounts/new/entity-account/create`,
		name: 'settings-entity-account-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-entity-account-information-edit.vue')
	},
	{
		path: `/account/settings/entity-information/edit`,
		alias: `/account/investment-accounts/entity-account/entity-information/edit`,
		name: 'settings-entity-account-information-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'LLC') {
				await investmentEntity.storeEntityAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-entity-account-information-edit.vue')
	},
	{
		path: `/account/settings/entity-information`,
		alias: '/account/investment-accounts/entity-account/personal-information',
		name: 'settings-entity-account-information-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'LLC') {
				await investmentEntity.storeEntityAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-entity-account-information-view.vue')
	},
	{
		path: `/account/settings/investment-plan/edit`,
		alias: [
			`/account/investment-accounts/investment-plan/edit`,
			`/account/investment-accounts/investment-goal/edit`
		],
		name: 'settings-investment-plan-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const riaPlan = investmentEntity.riaPlan;

			if (riaPlan) {
				next();
			} else {
				next({ name: 'account-overview', replace: true });
			}
		},
		component: () => import('@views/settings/settings-investment-plan-edit.vue'),
		redirect: { name: 'settings-investment-plan-edit-select-plan' },
		children: [
			{
				path: 'select-plan',
				name: 'settings-investment-plan-edit-select-plan',
				meta: { step: 0, minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
				component: () => import('@views/settings/settings-investment-plan-edit-select-plan.vue')
			},
			{
				path: 'confirm-plan',
				name: 'settings-investment-plan-edit-confirm-plan',
				meta: { step: 1, minTokenScopeRequired: 'FULL_ACCESS', suppressDefaultMxpViewEvent: true },
				beforeEnter: async (to, from, next) => {
					const plansStore = usePlansStore();
					if (plansStore.selectedPlan) {
						next();
					} else {
						next({ name: 'settings-investment-plan-edit-select-plan', replace: true });
					}
				},
				component: () => import('@views/settings/settings-investment-plan-edit-confirm-plan.vue')
			}
		]
	},
	{
		path: `/account/settings/investment-plan`,
		alias: [
			'/account/investment-accounts/investment-plan/view',
			'/account/investment-accounts/investment-goal/manage'
		],
		name: 'settings-investment-plan-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const riaPlan = investmentEntity.riaPlan;

			if (riaPlan) {
				next();
			} else {
				next({ name: 'account-overview', replace: true });
			}
		},
		component: () => import('@views/settings/settings-investment-plan-view.vue')
	},
	{
		path: `/account/settings/investment-plan/allocation/edit`,
		name: 'settings-investment-plan-allocation-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const riaPlan = investmentEntity.riaPlan;

			if (riaPlan) {
				if (riaPlan.category.name === 'CUSTOM') {
					await customPlans.fetchAndStoreCustomAllocationPlan();
					next();
				} else {
					next({ name: 'settings-investment-plan-view', replace: true });
				}
			} else {
				next({ name: 'account-overview', replace: true });
			}
		},
		component: () => import('@views/settings/settings-investment-plan-allocation-edit.vue')
	},
	{
		path: `/account/settings/nickname`,
		alias: `/account/investment-accounts/nickname/view`,
		name: 'settings-nickname-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-nickname-view.vue')
	},
	{
		path: `/account/settings/nickname/edit`,
		alias: '/account/investment-accounts/nickname/edit',
		name: 'settings-nickname-form',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-nickname-form.vue')
	},
	{
		path: `/account/profile/mobile-number/edit`,
		alias: '/account/security/mobile-number/edit',
		name: 'profile-mobile-number-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		beforeEnter: async (to, from, next) => {
			await user.storePhoneData();
			next();
		},
		component: () => import('@views/settings/profile/profile-mobile-number-edit.vue')
	},
	{
		path: `/account/profile/address/edit`,
		name: 'profile-address-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.storeAddress();
			next();
		},
		component: () => import('@views/settings/profile/profile-address-edit.vue')
	},
	{
		path: `/account/profile/personal-information`,
		alias: `/account/investment-accounts/individual-account/personal-information`,
		name: 'profile-personal-information-view',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			await user.storeAddress();
			await user.storePhoneData();
			if (app.isAdvisor && !advisor.investmentAdvisorDetails) {
				await advisor.getAdvisorData();
			}
			next();
		},
		component: () => import('@views/settings/profile/profile-personal-information-view.vue')
	},
	{
		path: `/account/settings/ira-information`,
		alias: '/account/investment-accounts/ira/personal-information',
		name: 'settings-ira-information-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'IRA') {
				await investmentEntity.storeIraAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-ira-information-view.vue')
	},
	{
		path: `/account/security/password/edit`,
		alias: '/account/security/manage-password',
		name: 'profile-password-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/profile/profile-password-edit.vue')
	},
	{
		path: `/account/security/removed-device/:deviceId`,
		alias: `/account/security/removedDevice/:deviceId`,
		name: 'profile-removed-device',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/profile/profile-removed-device.vue')
	},
	{
		path: `/account/profile/investment-accounts/trust/create`,
		alias: '/account/investment-accounts/new/trust/create',
		name: 'settings-trust-account-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-trust-information-edit.vue')
	},
	{
		path: `/account/settings/trust-information`,
		alias: '/account/investment-accounts/trust/personal-information',
		name: 'settings-trust-information-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'TRUST') {
				await investmentEntity.storeTrustAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-trust-information-view.vue')
	},
	{
		path: `/account/settings/trust-information/edit`,
		alias: '/account/investment-accounts/trust/information/edit',
		name: 'settings-trust-information-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.entityType === 'TRUST') {
				await investmentEntity.storeTrustAccountDetails();
				next();
			} else {
				next({ name: 'settings-account-information-redirect', replace: true });
			}
		},
		component: () => import('@views/settings/settings-trust-information-edit.vue')
	},
	{
		path: '/account/security/your-devices/edit',
		name: 'profile-your-devices-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/profile/profile-your-devices-edit.vue'),
		beforeEnter: async (to, from, next) => {
			await user.storeDevices();
			next();
		}
	},
	{
		path: `/account/security/two-factor-authentication/manage`,
		name: 'profile-two-factor-authentication-edit',
		meta: {
			minTokenScopeRequired: 'FULL_ACCESS',
			additionalAllowedScopes: ['ADVISOR_ACCESS', 'ADVISOR_READ_ACCESS']
		},
		beforeEnter: async (to, from, next) => {
			await user.storeTwoFactorType();
			next();
		},
		component: () => import('@views/settings/profile/profile-two-factor-authentication-edit.vue')
	},
	{
		path: `/account/settings/accreditation/edit`,
		alias: `/account/investment-accounts/accreditation/edit`,
		name: 'settings-accreditation-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS', additionalAllowedScopes: ['ADVISOR_ACCESS'] },
		component: () => import('@views/settings/settings-accreditation-edit.vue')
	},
	{
		path: `/account/settings/payment-methods`,
		alias: `/account/investment-accounts/payment-methods/manage`,
		name: 'settings-payment-methods-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storePaymentMethods();
			next();
		},
		component: () => import('@views/settings/settings-payment-methods-view.vue')
	},
	{
		path: `/account/settings/payment-methods/edit`,
		alias: `/account/investment-accounts/payment-methods/edit`,
		name: 'settings-payment-methods-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storePaymentMethods();
			next();
		},
		component: () => import('@views/settings/settings-payment-methods-edit.vue')
	},
	{
		path: '/account/bank-accounts/new',
		name: 'settings-bank-accounts-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-bank-accounts-new.vue')
	},
	{
		path: `/account/settings/advanced-options`,
		alias: [
			'/account/investment-accounts/advanced-options/manage',
			'/account/investment-accounts/advanced-options/view',
			'/account/investment-accounts/individual-account/advanced-options/manage'
		],
		name: 'settings-advanced-options',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/settings-advanced-options.vue')
	},
	{
		path: `/account/settings/beneficiaries/:beneficiaryId/edit`,
		alias: `/account/investment-accounts/beneficiaries/:beneficiaryId/edit`,
		name: 'settings-beneficiary-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeBeneficiaries();
			next();
		},
		component: () => import('@views/settings/settings-beneficiary-form.vue')
	},
	{
		path: `/account/settings/nav-reinvestment/edit`,
		name: 'settings-nav-reinvestment-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			if (investmentEntity.isLegacyInvestorWithoutPlan) {
				next({ name: 'settings-reinvestment-view', replace: true });
			} else {
				await investmentEntity.storeNAVReinvestmentPreference();
				next();
			}
		},
		component: () => import('@views/settings/settings-nav-reinvestment-edit.vue')
	},
	{
		path: `/account/settings/beneficiaries`,
		alias: [`/account/investment-accounts/beneficiaries/view`, `/account/investment-accounts/beneficiaries/manage`],
		name: 'settings-beneficiaries-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeBeneficiaries();
			next();
		},
		component: () => import('@views/settings/settings-beneficiaries-view.vue')
	},
	{
		path: `/account/settings/beneficiaries/new/:beneficiaryType`,
		alias: `/account/investment-accounts/beneficiaries/new/:beneficiaryType`,
		name: 'settings-beneficiary-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeBeneficiaries();
			next();
		},
		component: () => import('@views/settings/settings-beneficiary-form.vue')
	},
	{
		path: `/account/settings/notification-recipients`,
		alias: [
			`/account/investment-accounts/notification-recipients/view`,
			`/account/investment-accounts/notification-recipients/manage`
		],
		name: 'settings-email-recipients-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeEmailRecipients();
			next();
		},
		component: () => import('@views/settings/settings-email-recipients-view.vue')
	},
	{
		path: `/account/settings/notification-recipients/add`,
		alias: `/account/investment-accounts/notification-recipients/add`,
		name: 'settings-email-recipients-new',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeEmailRecipients();
			next();
		},
		component: () => import('@views/settings/settings-email-recipients-form.vue')
	},
	{
		path: `/account/settings/notification-recipients/:recipientId/edit`,
		alias: `/account/investment-accounts/notification-recipients/:recipientId/edit`,
		name: 'settings-email-recipients-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeEmailRecipients();
			next();
		},
		component: () => import('@views/settings/settings-email-recipients-form.vue')
	},
	{
		path: `/account/settings/document-delivery/edit`,
		alias: [
			`/account/investment-accounts/document-delivery/edit`,
			`/account/investment-accounts/document-delivery/manage`
		],
		name: 'settings-document-delivery-edit',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await investmentEntity.storeDocumentDeliveryPreference();
			next();
		},
		component: () => import('@views/settings/settings-document-delivery-edit.vue')
	},
	{
		path: `/account/security/two-factor-authentication/confirm-disable`,
		name: 'profile-two-factor-authentication-disable',
		meta: { minTokenScopeRequired: 'PRIVILEGED_ACCESS', additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS'] },
		beforeEnter: async (to, from, next) => {
			await user.storeTwoFactorType();
			if (user.twoFactorType === 'NONE') {
				await next({ name: 'profile-two-factor-authentication-edit', replace: true });
			} else {
				await next();
			}
		},
		component: () =>
			import('@views/settings/profile/two-factor-authentication/profile-two-factor-authentication-disable.vue')
	},
	{
		path: `/account/security/two-factor-authentication`,
		name: 'profile-two-factor-authentication',
		meta: { minTokenScopeRequired: 'PRIVILEGED_ACCESS', additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS'] },
		beforeEnter: async (to, from, next) => {
			await user.storeTwoFactorType();
			if (user.twoFactorType !== 'NONE') {
				await next({ name: 'profile-two-factor-authentication-edit', replace: true });
			} else {
				await next();
			}
		},
		component: () =>
			import('@views/settings/profile/two-factor-authentication/profile-two-factor-authentication.vue'),
		redirect: { name: 'profile-two-factor-authentication-select' },
		children: [
			{
				path: 'select',
				name: 'profile-two-factor-authentication-select',
				meta: {
					step: 1,
					minTokenScopeRequired: 'PRIVILEGED_ACCESS',
					additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS']
				},
				component: () =>
					import(
						'@views/settings/profile/two-factor-authentication/profile-two-factor-authentication-select.vue'
					)
			},
			{
				path: 'sms',
				name: 'profile-two-factor-authentication-sms',
				meta: {
					step: 2,
					minTokenScopeRequired: 'PRIVILEGED_ACCESS',
					additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS']
				},
				component: () =>
					import(
						'@views/settings/profile/two-factor-authentication/profile-two-factor-authentication-sms.vue'
					)
			},
			{
				path: 'sms/verify',
				name: 'profile-two-factor-authentication-sms-verify',
				meta: {
					step: 3,
					minTokenScopeRequired: 'PRIVILEGED_ACCESS',
					additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS']
				},
				component: () =>
					import(
						'@views/settings/profile/two-factor-authentication/profile-two-factor-authentication-sms-verify.vue'
					)
			},
			{
				path: 'totp',
				name: 'profile-two-factor-authentication-totp',
				meta: {
					step: 2,
					minTokenScopeRequired: 'PRIVILEGED_ACCESS',
					additionalAllowedScopes: ['ADVISOR_PRIVILEGED_ACCESS']
				},
				component: () =>
					import(
						'@views/settings/profile/two-factor-authentication/profile-two-factor-authentication-totp.vue'
					)
			}
		]
	},
	{
		path: `/account/select-entity`,
		name: 'investment-entity-selector',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		component: () => import('@views/settings/investment-entity-selector.vue')
	},
	{
		path: `/account/profile/advisor-connection`,
		name: 'profile-advisor-connection-view',
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			const hasFeatureFlag = await app.isFeatureEnabled('advisors-product');
			if (user.hasAnyAdvisorRelationship || hasFeatureFlag) {
				next();
			} else {
				next({ name: 'profile-personal-information-view', replace: true });
			}
		},
		component: () => import('@views/settings/profile/profile-advisor-connection-view.vue')
	},
	{
		path: `/account/security/authorizations/edit`,
		name: `profile-oauth-authorization-edit`,
		meta: { minTokenScopeRequired: 'FULL_ACCESS' },
		beforeEnter: async (to, from, next) => {
			await user.getAuthorizations();
			if (user.authorizations.length === 0) {
				next({ name: 'profile-security-view', replace: true });
			} else {
				next();
			}
		},
		component: () => import('@views/settings/profile/profile-oauth-authorization-edit.vue')
	}
];

export default settingsRoutes;
