import { AxiosRequestConfig, AxiosRequestInterceptorParams } from 'axios';
import { resetPassword } from '@store/modules/reset-password';

/**
 * Adds password reset session UUID as a header
 */
function passwordResetSessionUuidHeader(config: AxiosRequestConfig): AxiosRequestConfig {
	if (resetPassword.passwordResetSessionUuid === '') {
		throw new Error('UUID must be present in store to add to header');
	}
	const passwordResetSessionUuid = {
		'password-reset-session-uuid': resetPassword.passwordResetSessionUuid
	};
	config.headers = { ...config.headers, ...passwordResetSessionUuid };
	return config;
}

export const passwordResetSessionUuidHeaderInterceptor: AxiosRequestInterceptorParams = [
	(config) => passwordResetSessionUuidHeader(config),
	(error) => Promise.reject(error)
];
