import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	getForgotPasswordMetaData,
	sendForgotPasswordMobileCode,
	submitForgotPasswordAuthCode
} from '@api/forgot-password';
import { TwoFactorAuthCodeForm, TwoFactorCodeDeliveryMethod } from 'types/authentication';
import { ForgotPasswordMetaData } from 'types/forgot-password';
import store from '..';

@Module({
	namespaced: true,
	name: 'resetPassword',
	store
})
class ResetPasswordModule extends VuexModule {
	dataRetrieved = false;
	forgotPasswordMetaData: ForgotPasswordMetaData | null = null;
	lastFourOnly = false;
	passwordResetKey = '';
	passwordResetSessionUuid = '';
	attemptsRemaining = '';
	selectedAuthCodeDeliveryMethod: TwoFactorCodeDeliveryMethod = 'NONE';
	defaultAttemptsCount = '5';

	@Action({ rawError: true })
	public async initiateResetPasswordFlow(): Promise<void> {
		const forgotPasswordFlow = await getForgotPasswordMetaData();
		this.UPDATE_DATA_RETRIEVED(true);
		this.UPDATE_FORGOT_PASSWORD_METADATA(forgotPasswordFlow);

		if (forgotPasswordFlow.attemptsLeft) {
			this.UPDATE_ATTEMPTS_REMAINING(forgotPasswordFlow.attemptsLeft);
		}
	}

	@Action({ rawError: true })
	public async setAttemptsRemaining(attempts: string): Promise<void> {
		this.UPDATE_ATTEMPTS_REMAINING(attempts);
	}

	@Action({ rawError: true })
	public async sendMobileAuthCode(method: TwoFactorCodeDeliveryMethod) {
		this.UPDATE_2FA_CODE_DELIVERY_METHOD(method);
		await sendForgotPasswordMobileCode(method);
	}

	@Action({ rawError: true })
	public async verifyMobileAuthCode(twoFactorAuthCodeForm: TwoFactorAuthCodeForm): Promise<void> {
		await submitForgotPasswordAuthCode(twoFactorAuthCodeForm.authCode, twoFactorAuthCodeForm.codeDeliveryMethod);
	}

	@Action({ rawError: true })
	public async setLastFourOnly(lastFourOnly: boolean): Promise<void> {
		this.UPDATE_LAST_FOUR_ONLY(lastFourOnly);
	}

	@Action({ rawError: true })
	public async setDataRetrieved(dataRetrieved: boolean): Promise<void> {
		this.UPDATE_DATA_RETRIEVED(dataRetrieved);
	}

	@Action({ rawError: true })
	public reset(): void {
		this.UPDATE_DATA_RETRIEVED(false);
		this.UPDATE_FORGOT_PASSWORD_METADATA(null);
		this.UPDATE_LAST_FOUR_ONLY(false);
		this.UPDATE_PASSWORD_RESET_KEY('');
		this.UPDATE_PASSWORD_RESET_SESSION_UUID('');
		this.UPDATE_ATTEMPTS_REMAINING('5');
		this.UPDATE_2FA_CODE_DELIVERY_METHOD('NONE');
	}

	@Mutation
	public UPDATE_ATTEMPTS_REMAINING(attemptsRemaining: string): void {
		this.attemptsRemaining = attemptsRemaining;
	}

	@Mutation
	public UPDATE_DATA_RETRIEVED(dataRetrieved: boolean): void {
		this.dataRetrieved = dataRetrieved;
	}

	@Mutation
	public UPDATE_FORGOT_PASSWORD_METADATA(forgotPasswordMetaData: ForgotPasswordMetaData | null): void {
		this.forgotPasswordMetaData = forgotPasswordMetaData;
	}

	@Mutation
	public UPDATE_LAST_FOUR_ONLY(lastFourOnly: boolean): void {
		this.lastFourOnly = lastFourOnly;
	}

	@Mutation
	public UPDATE_PASSWORD_RESET_KEY(passwordResetKey: string): void {
		this.passwordResetKey = passwordResetKey;
	}

	@Mutation
	public UPDATE_PASSWORD_RESET_SESSION_UUID(passwordResetSessionUuid: string): void {
		this.passwordResetSessionUuid = passwordResetSessionUuid;
	}

	@Mutation
	public UPDATE_2FA_CODE_DELIVERY_METHOD(selectedAuthCodeDeliveryMethod: TwoFactorCodeDeliveryMethod): void {
		this.selectedAuthCodeDeliveryMethod = selectedAuthCodeDeliveryMethod;
	}
}

if (!store.hasModule('resetPassword')) {
	store.registerModule('resetPassword', ResetPasswordModule);
}

export const resetPassword = getModule(ResetPasswordModule);
