import { app } from '@store/modules/app';
import { BaseAlertComponentType } from 'types/layout';
import { computed } from 'vue';
import { InfoModule } from 'types/add-funds';
import { investmentEntity } from '@store/modules/investment-entity';
import { user } from '@store/modules/user';

export const hasActiveAutoInvestSchedule = computed(
	(): boolean => investmentEntity.hasActiveRecurringInvestmentSchedule && !hasPausedAutoInvestSchedule.value
);
export const hasPausedAutoInvestSchedule = computed((): boolean =>
	Boolean(investmentEntity.autoInvestSchedule?.resumeDate)
);
export const hasNeverBeenAnInvestor = computed((): boolean => investmentEntity.hasNeverBeenAnInvestor);
export const isAdvisor = computed((): boolean => app.isAdvisor);
export const isAuthenticated = computed((): boolean => app.isAuthenticated);
export const isDesktop = computed((): boolean => app.screenSize === 'DESKTOP');
export const isExistingInvestor = computed((): boolean => user.isExistingInvestor);
export const isMobile = computed((): boolean => app.screenSize === 'MOBILE');
export const isRepeatInvestor = computed(
	(): boolean => app.isAuthenticated && investmentEntity.additionalInvestmentEligibility === 'VALID'
);
export const isPremiumUser = computed((): boolean => user.isPremiumUser);
export const isProUser = computed((): boolean => user.isSubscriptionActive);
export const isProOrPremium = computed((): boolean => user.isProOrPremium);
export const isTablet = computed((): boolean => app.screenSize === 'TABLET');
export const isVerifiedAccredited = computed((): boolean => investmentEntity.isVerifiedAccredited);
export const isWebview = computed((): boolean => app.isMobileWebview);

export function mapInfoModuleToBaseAlert(
	infoModule: InfoModule | null | undefined
): BaseAlertComponentType | undefined {
	return infoModule
		? {
				heading: infoModule.title,
				iconUrl: infoModule.icon,
				message: infoModule.body ? [infoModule.body] : []
			}
		: undefined;
}
