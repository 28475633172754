import { RouteLocationNormalized, Router } from 'vue-router';
import { getItemFromSessionStorageAsBoolean } from './web-storage';
import { Link } from 'types/layout';
import { modularDashboard } from '@store/modules/modular-dashboard';
import { redirect } from './client';

/**
 * Determines whether a path is part of the Sign-Up flow by checking against known Sign-Up route roots.
 * @param path
 */
export const isSignUpRoute = (path: string): boolean => {
	const roots = [
		'questionnaire',
		'question',
		'signup',
		'info',
		'reits/signup-checkout',
		'reits/checkout',
		'ira/start',
		'ira/checkout'
	];
	return roots.some((root) => path.startsWith(`/${root}/`));
};

export const handleRouting = async (link: Link, router: Router): Promise<void> => {
	if (link.router) {
		try {
			await router.push({ name: link.router, query: link.query, params: link.params });
		} catch {
			// this is to avoid an annoying error in the current version of Vue Router and the creator of Vue Router explicitly told people to do this - Jake Kenneally
			// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
		}
	} else if (link.href) {
		redirect(link.href);
	} else {
		throw new Error('Routing error');
	}
};

export const stripRouteHash = (route: RouteLocationNormalized): string => {
	const fullPath = route.fullPath;
	const hashIndex = fullPath.indexOf('#');
	const queryIndex = fullPath.indexOf('?');
	if (hashIndex > -1) {
		if (queryIndex > hashIndex) {
			return fullPath.substring(0, hashIndex) + fullPath.substring(queryIndex, fullPath.length);
		}
		return fullPath.substring(0, hashIndex);
	}
	return fullPath;
};

export const checkChecklistRedirect = async (
	router: Router,
	defaultRoute: string,
	routeParams?: Record<string, string>
): Promise<void> => {
	/**
	 * If an action is taken that would affect the Account Checklist module, reset the modular overview.
	 *
	 * This is an excessive but necessary method to allow the module to re-hydrate if an outstanding step is resolved
	 * (either manually or via the module), and to drop the module from the overview if necessary.
	 */
	if (modularDashboard.accountChecklistModuleData) {
		await modularDashboard.resetAll();
	}

	const isChecklistRedirect = getItemFromSessionStorageAsBoolean('redirect-to-checklist');
	if (isChecklistRedirect) {
		await router.push({ name: 'account-checklist' });
	} else {
		await router.push({ name: defaultRoute, params: routeParams });
	}
};

export const getRouteHash = (route: RouteLocationNormalized): string => {
	return route?.hash?.split('#')[1] || '';
};
