import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
	AddFundsFlow,
	InfoModule,
	OrderGroupResponse,
	RepeatOfferingInvestmentMetadata,
	RepeatOfferingInvestmentSelections,
	RepeatOrderGroupResponse,
	RepeatRiaInvestmentSelections,
	RiaInvestTabAddFundsMetadata,
	TransactionType,
	ValidOfferingInvestmentRange,
	ValidRiaInvestmentRange
} from 'types/add-funds';
import {
	cacheOfferingSelections,
	cacheRiaSelections,
	getAddFundsOrderViewDetails,
	getCrossSellEligibility,
	getInvestTabAddFundsData,
	getOfferingReservationWindows,
	getRepeatOfferingInvestmentData,
	getRepeatOfferingInvestmentSelectionData
} from '@api/add-funds';
import { CheckoutMixpanelEvent, CheckoutOrderGroupDetails, CrossSellResponse } from 'types/checkout';
import { createOrderGroup, placeOrder } from '@api/checkout';
import { ReitType, ReservationWindow } from 'types/reit-element';
import { DirectInvestmentEligibility } from 'types/reit-details';
import { investmentEntity } from './investment-entity';
import { K1Acknowledgement } from 'types/form';
import { PaymentMethod } from 'types/payment';
import { SelectOptionAmount } from 'types/layout';
import store from '..';

@Module({
	namespaced: true,
	name: 'addFunds',
	store
})
class AddFundsModule extends VuexModule {
	amount: string | null = null;
	infoModule: InfoModule | null = null;
	cachedOfferingAddFundsSelections: RepeatOfferingInvestmentSelections | null = null;
	containsEReits = false;
	paymentMethod: PaymentMethod | null = null;
	paymentMethodId = '';
	paymentMethodType: TransactionType | null = null;
	investTabAddFundsMetadata: RiaInvestTabAddFundsMetadata | null = null;
	suggestedAchOfferingInvestmentAmountOptions: Array<SelectOptionAmount> = [];
	suggestedWireOfferingInvestmentAmountOptions: Array<SelectOptionAmount> = [];
	reitInvestmentOrderGroupId = '';
	reitDocumentRevisionIds: Array<string> = [];
	validRiaInvestmentRange: ValidRiaInvestmentRange = {
		riaPlanId: '0',
		achMinInvestment: '0',
		achMaxInvestment: '0'
	};
	validOfferingInvestmentRange: ValidOfferingInvestmentRange = {
		reitOfferingVintageId: '0',
		achMinInvestment: '0',
		achMaxInvestment: '0',
		wireMinInvestment: '0',
		wireMaxInvestment: '0',
		ipoMinInvestment: '0',
		ipoMaxInvestment: '0'
	};
	orderGroupResponse: OrderGroupResponse | null = null;
	repeatOrderGroupResponse: RepeatOrderGroupResponse | null = null;
	bankAccountLinked = false;
	investTabAddFunds = false;
	orderComplete = false;
	offeringDataLoaded = false;
	offeringSubmissionErrorCode: string | null = null;
	amountAltText: string | null = null;
	offeringName: string | null = null;
	offeringType: ReitType | null = null;
	offeringId: string | null = null;
	offeringVintageId: string | null = null;
	requiresK1Acknowledgment = false;
	addFundsFlow: AddFundsFlow | null = null;
	investmentEligibility: DirectInvestmentEligibility | null = null;
	eligibleToInvestInOffering = false;
	acknowledgedK1Disclosure: K1Acknowledgement = { acknowledged: false };
	selectedWindow: ReservationWindow | null = null;
	reservationWindows: Array<ReservationWindow> = [];
	hasMultipleWindows = false;
	crossSellResponse: CrossSellResponse | null = null;

	get checkoutMixpanelProperties(): CheckoutMixpanelEvent {
		let properties: CheckoutMixpanelEvent;
		if (this.addFundsFlow === 'EDIRECT') {
			properties = {
				'Order Type': 'Direct Investment',
				'Direct Investment': this.offeringName ?? ''
			};
		} else {
			properties = {
				'Order Type': 'RIA',
				'RIA Plan': investmentEntity.currentRiaPlanName
			};
		}
		properties['Entity Type'] = investmentEntity.entityTypeLabel;
		properties['Transaction Type'] = this.paymentMethodType ?? 'ACH';

		if (this.reitInvestmentOrderGroupId && this.orderGroupResponse) {
			properties['Investment Amount'] = Number(this.amount);
			properties['Investments'] = this.orderGroupResponse.investments.map((investment) => {
				return investment.reitName;
			});
			if (this.bankAccountLinked) {
				properties['Payment Method Setup'] = 'New Account';
				properties['Payment Method Creation Method'] = 'Plaid';
			} else {
				properties['Payment Method Setup'] = 'Existing Account';
			}
		}

		return properties;
	}

	@Action({ rawError: true })
	public async fetchAndStoreRepeatOfferingInvestmentMetadata(): Promise<void> {
		const cohortId = this.selectedWindow?.cohortId ?? null;
		const repeatOfferingInvestmentMetadata = await getRepeatOfferingInvestmentData(
			this.offeringVintageId ?? '',
			cohortId
		);

		this.UPDATE_REPEAT_OFFERING_INVESTMENT_METADATA(repeatOfferingInvestmentMetadata);
		this.UPDATE_OFFERING_DATA_LOADED(true);
	}

	@Action({ rawError: true })
	public async fetchAndStoreReservationWindowData(cohortId: string | null): Promise<void> {
		const reservationWindows = await getOfferingReservationWindows(this.offeringVintageId ?? '');
		this.UPDATE_RESERVATION_WINDOW_DATA({ reservationWindows, cohortId });
	}

	@Action({ rawError: true })
	public async fetchAndStoreAddFundsMetadata(): Promise<void> {
		const investTabAddFundsMetadata = await getInvestTabAddFundsData();
		this.UPDATE_INVEST_TAB_ADD_FUNDS_METADATA(investTabAddFundsMetadata);
	}

	@Action({ rawError: true })
	public clearPreviousOrder(): void {
		this.UPDATE_AMOUNT(null);
		this.UPDATE_PAYMENT_METHOD_ID('');
		this.UPDATE_PAYMENT_METHOD_TYPE(null);
		this.UPDATE_REIT_DOCUMENT_REVISION_IDS([]);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID('');
		this.UPDATE_ORDER_COMPLETE(false);
	}

	@Action({ rawError: true })
	public resetInvestTabAddFunds(): void {
		this.UPDATE_INVEST_TAB_ADD_FUNDS(false);
	}

	@Action({ rawError: true })
	public async submitAgreements(reitDocumentRevisionIds: Array<string>): Promise<string> {
		this.UPDATE_REIT_DOCUMENT_REVISION_IDS(reitDocumentRevisionIds);
		if (this.addFundsFlow === 'EDIRECT') {
			return 'add-funds-offering-review';
		}
		return 'add-funds-ria-review';
	}

	@Action({ rawError: true })
	public async storeAndCacheRiaSelections(selections: RepeatRiaInvestmentSelections): Promise<void> {
		this.UPDATE_AMOUNT(selections.amount);
		this.UPDATE_PAYMENT_METHOD_ID(selections.bankAccountId ? selections.bankAccountId : selections.paymentMethodId);
		this.UPDATE_PAYMENT_METHOD_TYPE(selections.paymentMethodType);
		await cacheRiaSelections(selections);
	}

	@Action({ rawError: true })
	public async storeAndCacheOfferingSelections(selections: RepeatOfferingInvestmentSelections): Promise<void> {
		this.UPDATE_AMOUNT(selections.amount);
		this.UPDATE_PAYMENT_METHOD_ID(selections.paymentMethodId);
		this.UPDATE_PAYMENT_METHOD_TYPE(selections.paymentMethodType);
		await cacheOfferingSelections(selections);
	}

	@Action({ rawError: true })
	public async fetchAndUpdatePriorOfferingSelections(): Promise<void> {
		const priorSelections = await getRepeatOfferingInvestmentSelectionData();

		addFunds.UPDATE_OFFERING_VINTAGE_ID(priorSelections.offeringVintageId);
	}

	@Action({ rawError: true })
	public async createOfferingOrder(): Promise<string> {
		const order: CheckoutOrderGroupDetails = {
			amount: this.amount ?? '0',
			paymentMethodId: this.paymentMethodId,
			paymentMethodType: this.paymentMethodType,
			reitOfferingVintageId: this.offeringVintageId,
			cohortId: this.selectedWindow?.cohortId ?? ''
		};
		const orderGroupResponse = await createOrderGroup(order);

		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(orderGroupResponse.orderGroupId ?? '');
		this.UPDATE_ORDER_GROUP_RESPONSE(orderGroupResponse);

		const repeatOrderGroupResponse = await getAddFundsOrderViewDetails();
		this.UPDATE_REPEAT_ORDER_GROUP_RESPONSE(repeatOrderGroupResponse);

		return 'add-funds-offering-review';
	}

	@Action({ rawError: true })
	public async createCrossSellOfferingOrder(): Promise<string> {
		const order: CheckoutOrderGroupDetails = {
			amount: this.amount ?? '0',
			paymentMethodId: this.paymentMethodId,
			paymentMethodType: this.paymentMethodType,
			reitOfferingVintageId: this.offeringVintageId
		};
		const orderGroupResponse = await createOrderGroup(order);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(orderGroupResponse.orderGroupId ?? '');
		this.UPDATE_ORDER_GROUP_RESPONSE(orderGroupResponse);

		const repeatOrderGroupResponse = await getAddFundsOrderViewDetails();
		this.UPDATE_REPEAT_ORDER_GROUP_RESPONSE(repeatOrderGroupResponse);

		return 'cross-sell-offering-review';
	}

	@Action({ rawError: true })
	public async createRiaOrder(orderDetails: { riaPlanId: string }): Promise<string> {
		const order: CheckoutOrderGroupDetails = {
			amount: this.amount ?? '0',
			paymentMethodId: this.paymentMethodId,
			paymentMethodType: this.paymentMethodType,
			riaPlanId: orderDetails.riaPlanId
		};
		const orderGroupResponse = await createOrderGroup(order);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(orderGroupResponse.orderGroupId ?? '');
		this.UPDATE_ORDER_GROUP_RESPONSE(orderGroupResponse);

		const repeatOrderGroupResponse = await getAddFundsOrderViewDetails();
		this.UPDATE_REPEAT_ORDER_GROUP_RESPONSE(repeatOrderGroupResponse);
		this.UPDATE_CONTAINS_EREITS(repeatOrderGroupResponse.containsEReits);

		return 'add-funds-ria-review';
	}

	@Action({ rawError: true })
	public storePaymentMethod(paymentMethod: PaymentMethod | null): void {
		this.UPDATE_PAYMENT_METHOD(paymentMethod);
		this.UPDATE_PAYMENT_METHOD_ID(paymentMethod?.id ?? '');
		this.UPDATE_PAYMENT_METHOD_TYPE(paymentMethod?.paymentMethodType ?? null);
	}

	@Action({ rawError: true })
	public async submitReview(suppressToast = false): Promise<string> {
		const orderGroupResponse = await placeOrder(
			this.reitInvestmentOrderGroupId,
			this.reitDocumentRevisionIds,
			null,
			null,
			suppressToast
		);

		this.UPDATE_ORDER_GROUP_RESPONSE(orderGroupResponse);

		this.UPDATE_ORDER_COMPLETE(true);

		await investmentEntity.refreshPositionsAndPerformance();

		switch (this.addFundsFlow) {
			case 'EDIRECT':
				return 'add-funds-offering-success';
			case 'CROSS_SELL':
				return 'cross-sell-offering-success';
			case 'RIA':
			default:
				return 'add-funds-ria-success';
		}
	}

	@Action({ rawError: true })
	async checkCrossSellEligibility(): Promise<void> {
		const crossSellResponse = await getCrossSellEligibility();

		this.UPDATE_PAYMENT_METHOD_ID(crossSellResponse.paymentMethod?.bankAccount?.id ?? '');
		this.UPDATE_PAYMENT_METHOD_TYPE(crossSellResponse.paymentMethod?.paymentMethodType ?? null);
		this.UPDATE_OFFERING_VINTAGE_ID(crossSellResponse.vintageId ?? null);

		this.UPDATE_CROSS_SELL_RESPONSE(crossSellResponse);
	}

	@Action({ rawError: true })
	updateSelectedWindow(window: ReservationWindow): void {
		this.UPDATE_SELECTED_WINDOW(window);
	}

	@Action({ rawError: true })
	resetReservationWindowData(): void {
		this.UPDATE_RESERVATION_WINDOW_DATA({ reservationWindows: [], cohortId: null });
	}

	@Action({ rawError: true })
	resetAll(): void {
		this.UPDATE_AMOUNT(null);
		this.UPDATE_CONTAINS_EREITS(false);
		this.UPDATE_PAYMENT_METHOD_ID('');
		this.UPDATE_PAYMENT_METHOD_TYPE(null);
		this.UPDATE_SUGGESTED_ACH_OFFERING_INVESTMENT_AMOUNT_OPTIONS([]);
		this.UPDATE_SUGGESTED_WIRE_OFFERING_INVESTMENT_AMOUNT_OPTIONS([]);
		this.UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID('');
		this.UPDATE_REIT_DOCUMENT_REVISION_IDS([]);
		this.UPDATE_ORDER_GROUP_RESPONSE(null);
		this.UPDATE_BANK_ACCOUNT_LINKED(false);
		this.UPDATE_INVEST_TAB_ADD_FUNDS(false);
		this.UPDATE_ORDER_COMPLETE(false);
		this.UPDATE_OFFERING_DATA_LOADED(false);
		this.UPDATE_OFFERING_SUBMISSION_ERROR_CODE(null);
		this.UPDATE_AMOUNT_ALT_TEXT(null);
		this.UPDATE_OFFERING_NAME(null);
		this.UPDATE_OFFERING_TYPE(null);
		this.UPDATE_OFFERING_ID(null);
		this.UPDATE_OFFERING_VINTAGE_ID(null);
		this.UPDATE_ADD_FUNDS_FLOW(null);
		this.UPDATE_ACKNOWLEDGED_K1_DISCLOSURE({ acknowledged: false });
		this.UPDATE_SELECTED_WINDOW(null);
		this.UPDATE_RESERVATION_WINDOW_DATA({ reservationWindows: [], cohortId: null });
	}

	@Mutation
	public UPDATE_REIT_DOCUMENT_REVISION_IDS(reitDocumentRevisionIds: Array<string>): void {
		this.reitDocumentRevisionIds = reitDocumentRevisionIds;
	}

	@Mutation
	public UPDATE_INVEST_TAB_ADD_FUNDS_METADATA(investTabAddFundsMetadata: RiaInvestTabAddFundsMetadata): void {
		this.validRiaInvestmentRange = investTabAddFundsMetadata.validRiaInvestmentRange;
		this.investTabAddFundsMetadata = investTabAddFundsMetadata;
	}

	@Mutation
	public UPDATE_REPEAT_OFFERING_INVESTMENT_METADATA(
		repeatOfferingInvestmentMetadata: RepeatOfferingInvestmentMetadata
	): void {
		this.eligibleToInvestInOffering = repeatOfferingInvestmentMetadata.eligibleToInvestInOffering;
		this.infoModule = repeatOfferingInvestmentMetadata.infoModule || null;
		this.investmentEligibility = repeatOfferingInvestmentMetadata.investmentEligibility;
		if (
			this.eligibleToInvestInOffering &&
			repeatOfferingInvestmentMetadata.validReitInvestmentRange &&
			repeatOfferingInvestmentMetadata.suggestedAchReitInvestmentAmounts &&
			repeatOfferingInvestmentMetadata.suggestedWireReitInvestmentAmounts
		) {
			this.validOfferingInvestmentRange = repeatOfferingInvestmentMetadata.validReitInvestmentRange;
			this.suggestedAchOfferingInvestmentAmountOptions =
				repeatOfferingInvestmentMetadata.suggestedAchReitInvestmentAmounts;
			this.suggestedWireOfferingInvestmentAmountOptions =
				repeatOfferingInvestmentMetadata.suggestedWireReitInvestmentAmounts;
		}
		this.cachedOfferingAddFundsSelections =
			repeatOfferingInvestmentMetadata.repeatOfferingInvestmentSelections ?? null;
		if (this.cachedOfferingAddFundsSelections !== null) {
			this.amount = this.amount ?? this.cachedOfferingAddFundsSelections.amount;
			this.paymentMethodType = this.cachedOfferingAddFundsSelections.paymentMethodType;
			this.paymentMethodId = this.cachedOfferingAddFundsSelections.paymentMethodId;
		}
		this.requiresK1Acknowledgment = repeatOfferingInvestmentMetadata.requiresK1Acknowledgment;
		this.offeringName = repeatOfferingInvestmentMetadata.offeringName;
		this.offeringType = repeatOfferingInvestmentMetadata.reitType;
		this.offeringId = repeatOfferingInvestmentMetadata.offeringId;
	}

	@Mutation
	public UPDATE_REPEAT_ORDER_GROUP_RESPONSE(repeatOrderGroupResponse: RepeatOrderGroupResponse): void {
		this.repeatOrderGroupResponse = repeatOrderGroupResponse;
	}

	@Mutation
	UPDATE_RESERVATION_WINDOW_DATA(data: {
		reservationWindows: Array<ReservationWindow>;
		cohortId: string | null;
	}): void {
		this.reservationWindows = data.reservationWindows;
		this.hasMultipleWindows =
			data.reservationWindows.filter((window) => window.status.name === 'AVAILABLE').length > 1;
		this.selectedWindow = data.cohortId
			? data.reservationWindows.find((window) => {
					return window.cohortId === data.cohortId;
				}) ?? null
			: null;
	}

	@Mutation
	public UPDATE_AMOUNT(amount: string | null): void {
		this.amount = amount;
	}

	@Mutation
	UPDATE_CONTAINS_EREITS(containsEReits: boolean) {
		this.containsEReits = containsEReits;
	}

	@Mutation
	public UPDATE_PAYMENT_METHOD(paymentMethod: PaymentMethod | null): void {
		this.paymentMethod = paymentMethod;
	}

	@Mutation
	public UPDATE_PAYMENT_METHOD_ID(paymentMethodId: string): void {
		this.paymentMethodId = paymentMethodId;
	}

	@Mutation
	public UPDATE_PAYMENT_METHOD_TYPE(paymentMethodType: TransactionType | null): void {
		this.paymentMethodType = paymentMethodType;
	}

	@Mutation
	public UPDATE_REIT_INVESTMENT_ORDER_GROUP_ID(reitInvestmentOrderGroupId: string): void {
		this.reitInvestmentOrderGroupId = reitInvestmentOrderGroupId;
	}

	@Mutation
	public UPDATE_ORDER_COMPLETE(complete: boolean): void {
		this.orderComplete = complete;
	}

	@Mutation
	public UPDATE_ORDER_GROUP_RESPONSE(orderGroupResponse: OrderGroupResponse | null): void {
		this.orderGroupResponse = orderGroupResponse;
	}

	@Mutation
	public UPDATE_BANK_ACCOUNT_LINKED(bankAccountLinked: boolean): void {
		this.bankAccountLinked = bankAccountLinked;
	}

	@Mutation
	public UPDATE_OFFERING_DATA_LOADED(offeringDataLoaded: boolean): void {
		this.offeringDataLoaded = offeringDataLoaded;
	}

	@Mutation
	public UPDATE_AMOUNT_ALT_TEXT(amountAltText: string | null): void {
		this.amountAltText = amountAltText;
	}

	@Mutation
	public UPDATE_INVEST_TAB_ADD_FUNDS(investTabAddFunds: boolean): void {
		this.investTabAddFunds = investTabAddFunds;
	}

	@Mutation
	public UPDATE_OFFERING_VINTAGE_ID(offeringVintageId: string | null): void {
		this.offeringVintageId = offeringVintageId;
	}

	@Mutation
	public UPDATE_OFFERING_NAME(offeringName: string | null): void {
		this.offeringName = offeringName;
	}

	@Mutation
	public UPDATE_OFFERING_TYPE(offeringType: ReitType | null): void {
		this.offeringType = offeringType;
	}

	@Mutation
	public UPDATE_OFFERING_ID(offeringId: string | null): void {
		this.offeringId = offeringId;
	}

	@Mutation
	public UPDATE_ADD_FUNDS_FLOW(addFundsFlow: AddFundsFlow | null): void {
		this.addFundsFlow = addFundsFlow;
	}

	@Mutation
	public UPDATE_ACKNOWLEDGED_K1_DISCLOSURE(acknowledgedK1Disclosure: K1Acknowledgement): void {
		this.acknowledgedK1Disclosure = acknowledgedK1Disclosure;
	}

	@Mutation
	UPDATE_OFFERING_SUBMISSION_ERROR_CODE(offeringSubmissionErrorCode: string | null): void {
		this.offeringSubmissionErrorCode = offeringSubmissionErrorCode;
	}

	@Mutation
	UPDATE_SELECTED_WINDOW(selectedWindow: ReservationWindow | null) {
		this.selectedWindow = selectedWindow;
	}

	@Mutation
	UPDATE_VALID_OFFERING_INVESTMENT_RANGE(validOfferingInvestmentRange: ValidOfferingInvestmentRange): void {
		this.validOfferingInvestmentRange = validOfferingInvestmentRange;
	}

	@Mutation
	UPDATE_SUGGESTED_ACH_OFFERING_INVESTMENT_AMOUNT_OPTIONS(
		suggestedAchOfferingInvestmentAmountOptions: Array<SelectOptionAmount>
	): void {
		this.suggestedAchOfferingInvestmentAmountOptions = suggestedAchOfferingInvestmentAmountOptions;
	}

	@Mutation
	UPDATE_SUGGESTED_WIRE_OFFERING_INVESTMENT_AMOUNT_OPTIONS(
		suggestedWireOfferingInvestmentAmountOptions: Array<SelectOptionAmount>
	): void {
		this.suggestedWireOfferingInvestmentAmountOptions = suggestedWireOfferingInvestmentAmountOptions;
	}

	@Mutation
	UPDATE_CROSS_SELL_RESPONSE(crossSellResponse: CrossSellResponse): void {
		this.crossSellResponse = crossSellResponse;
	}
}

if (!store.hasModule('addFunds')) {
	store.registerModule('addFunds', AddFundsModule);
}

export const addFunds = getModule(AddFundsModule);
