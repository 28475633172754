<template>
	<DesktopNavTemplate :styles="styles" :show-add-funds="showAddFunds">
		<template #left>
			<ul class="main-nav__menu ml-150">
				<li v-for="(link, accountLinkId) in nav.links" :key="accountLinkId" class="main-nav__menu-item">
					<NavigationLinkDesktop
						:link="link"
						:is-sticky="isSticky"
						:is-transparent-dark-text="isTransparentDarkText"
					/>
				</li>
			</ul>
		</template>
		<template #right>
			<div class="display-flex align-items-center">
				<ul class="main-nav__menu">
					<NotificationDropdown
						:expanded="notificationDropdownExpanded"
						@expand-notification-dropdown="expandNotificationDropdown"
					/>
					<UserDropdown
						:expanded="userDropdownExpanded"
						:has-multiple-entities="hasMultipleEntities"
						@expand-user-dropdown="expandUserDropdown"
					/>
				</ul>
			</div>
		</template>
	</DesktopNavTemplate>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { hasNeverBeenAnInvestor, isAdvisor } from '@utils/composables';
import AdvisorAuthenticatedNav from '@classes/AdvisorAuthenticatedNav';
import AuthenticatedNav from '@classes/AuthenticatedNav';
import DesktopNavTemplate from '@components/header/navigation/desktop/desktop-nav-template.vue';
import { HeaderStyle } from 'types/header';
import { InvestmentEntity } from 'types/investment-entity';
import { investmentEntity } from '@store/modules/investment-entity';
import NavigationLinkDesktop from './navigation-link-desktop.vue';
import NotificationDropdown from '@components/header/navigation/notification-dropdown.vue';
import { user } from '@store/modules/user';
import UserDropdown from '@components/header/navigation/user-dropdown.vue';

interface Props {
	isTransparent?: boolean;
	isSticky?: boolean;
	isTransparentDarkText?: boolean;
	styles: HeaderStyle;
}

withDefaults(defineProps<Props>(), {
	isTransparent: false,
	isSticky: false,
	isTransparentDarkText: false
});

const userDropdownExpanded = ref(false);
const notificationDropdownExpanded = ref(false);

const investmentEntityObject = computed((): InvestmentEntity => {
	return investmentEntity.investmentEntityObject;
});

const hasMultipleEntities = computed((): boolean => {
	return nav.value.multipleInvestmentEntities;
});

const nav = computed((): AuthenticatedNav | AdvisorAuthenticatedNav => {
	return isAdvisor.value
		? new AdvisorAuthenticatedNav(user.user)
		: new AuthenticatedNav(user.user, investmentEntityObject.value);
});

const showAddFunds = computed((): boolean => {
	return !isAdvisor.value && !hasNeverBeenAnInvestor.value;
});

function expandNotificationDropdown(expanded: boolean) {
	notificationDropdownExpanded.value = expanded;
	if (expanded && userDropdownExpanded.value) {
		userDropdownExpanded.value = false;
	}
}

function expandUserDropdown(expanded: boolean) {
	userDropdownExpanded.value = expanded;
	if (expanded && notificationDropdownExpanded.value) {
		notificationDropdownExpanded.value = false;
	}
}
</script>
